export const environment = {
  production: false,
  targetAPI: 'https://customer-middleware.afrikpay.com/',
  agentid : '6207991242753220',
  agentpwd : '6207@3220$',
  apikey : '98c1258e8967ee24f77e07fe7fe3e7a7',
  url : 'https://ellotrans.afrikpay.com',
  id : 'ellotrans.afrikpay.com',
  accepturl : 'https://ellotrans.afrikpay.com/payment/web/success',
  cancelurl : 'https://ellotrans.afrikpay.com/payment/web/failed',
  printurl : "http://invoice.afrikpay.com/print/",
  storage : 'home/oss'
};
